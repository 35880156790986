/*-------------------------
    Button
-------------------------*/
.calculate-btn {
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #006155;
    padding: 18px 55px;
    width: 40%;
    height: auto;
    text-align: center;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    &.btn-borderd {
        border: var(--border-gray);
        color: var(--color-dark);
        background-color: transparent;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
        }
    }
    &.btn-fill-primary {
        background-color: var(--color-primary);
        color: var(--color-white);
        border: 1px solid var(--color-primary);
        overflow: hidden;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: var(--color-link);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: var(--color-link);
            border-color: var(--color-link);
            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-white {
        background-color: var(--color-white);
        box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
        &:hover {
            background-color: var(--color-accent1);
            color: var(--color-white);
        }
    }
    &.btn-large {
        padding: 23px 55px;
        @media #{$md-layout} {
            padding: 18px 35px;
        }
    }
    &.btn-large-fluid {
        padding: 23px;
        width: 100%;
    }
    &.btn-fluid {
        width: 100%;
    }

    @media #{$md-layout} {
        font-size: 16px;
        padding: 18px 30px;
        width: 60%;
    }

    @media #{$sm-layout} {
        font-size: 16px;
        padding: 18px 30px;
        width: 70%;
    }
}

.buttonHolder {
    display: flex;
    justify-content: center;
    align-content: center;
}

/*-------------------------
   Header Button
-------------------------*/

.header-btn {
    border-radius: 15px;
    font-size: 16px !important ;
    font-weight: 500 !important;
    letter-spacing: -0.04em;
    color: #006155;
    padding: 18px 55px;
    height: auto !important;
    text-align: center !important;
    transition: var(--transition) !important;
    position: relative !important;
    z-index: 1;
    &.btn-borderd {
        border: var(--border-gray);
        color: var(--color-dark);
        background-color: transparent;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
        }
    }
    &.btn-fill-primary {
        background-color: var(--color-orange);
        color: var(--color-white);
        border: 1px solid var(--color-orange);
        overflow: hidden;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: var(--color-orange-dark);
            color: var(--color-white);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: var(--color-orange-dark);
            border-color: var(--color-orange-dark);

            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-white {
        background-color: var(--color-white);
        box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
        &:hover {
            background-color: var(--color-accent1);
            color: var(--color-white);
        }
    }
    &.btn-large {
        padding: 10px 20px;
        @media #{$md-layout} {
            padding: 18px 35pxs;
        }
    }
    &.btn-large-fluid {
        padding: 23px;
        width: 100%;
    }
    &.btn-fluid {
        width: 100%;
    }
}


/*-------------------------
   form container 
-------------------------*/


.calculator-form-box {
    //background-color: var(--color-white);
    padding: 50px;
    border-radius: 30px;
    @media #{$sm-layout} {
        padding: 40px 30px;
    }
    .title {
        font-size: 40px;
        @media #{$laptop-device} {
            font-size: 36px;
        }
        @media #{$smlg-device} {
            font-size: 32px;
        }
        @media #{$sm-layout} {
            font-size: 28px;
        }
    }
    .form-group {
        .axil-btn {
            &::after {
                width: calc(100% + 50px);
            }
        }
    }
    &.shadow-box {
        box-shadow: 0 20px 48px 0 rgba(153,161,170,.25);
    }
}

.formContainer {
    width: 100%;
}

.fourStackCard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.cardStep {
    background-color: var(--color-white);
    border-left: 12px solid #5DF500;
    border-radius: 20px ;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 25%;
    width: 24%;
    min-width: 20%;
    
    padding: 18px 15px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 20px;

    @media #{$md-layout} {
        max-width: none;
        width: 310px;
    }
    @media #{$sm-layout} {
        max-width: none;
        width: 90%;
    }
    
}

.textNumber {
    color: #48bc00;
    font-size: 45px;
}


.mt--50{
    margin-top: 50px;
}

///
/// Drop Down
/// 

.dropdownSelectContainer{

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;

}

.dropdownGropuContainer{
    width: 47%;

    
    @media #{$sm-layout} {
        max-width: none;
        width: 100%;
    }
    
    
}

.dropdownSelect {
    
    background: url(https://img.icons8.com/android/24/expand-arrow.png) no-repeat right #ffffff00;
    -webkit-appearance: none;
    background-position-x: calc( 100% - 25px );
}


//
//  Check Box 
//

.textCheckboxLabel {
    font-size: medium;
    color: var(--color-dark);
}
.toogleGropuContainer{
    width: 23%;
    min-width: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    
    @media #{$sm-layout} {
        max-width: none;
        width: 45%;
    }
    
}

.toogleGropuContainerServices{
    width: 560px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    
    @media #{$sm-layout} {
        max-width: none;
        width: 100%;
    }
}



.checkbox_item .checkbox_wrap{
	position: relative;
	display: block;
	cursor: pointer;
	width: 60px;
    height: 30px;
	margin: 0 auto 0px;
}

.checkbox_item:last-child .checkbox_wrap{
	margin-bottom: 0;
}

.checkbox_item .checkbox_wrap .checkbox_inp{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
    height: 30px;
}

.checkbox_item .checkbox_wrap .checkbox_mark{
	display: inline-block;
	position: relative;
	border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after{
	content: "";
	position: absolute;
	transition: all 0.5s ease;
}



/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark{
	background: #e3e3e3;
	width: 60px;
	height: 30px;
	padding: 2px;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before{
	top: 2.2px;
	left: 3px;
	width: 25px;
	height: 25px;
	background: #000;
	border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
	background: #5DF500;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
	left: 31px;
}




/// 
///
/// Comparison Table CSS
/// 
/// 

.disclaimerTest{
    font-size: 12px;
}

.iconsComparison{
    width: 23px;
    height: 23px;

    @media #{$md-layout} {
        width: 21px;
    height: 21px;
     }
    @media #{$sm-layout} {
        width: 18px;
    height: 18px;
     }
}

.topContainerComparison{
    width: 100%;
    padding: 0px 20px;

    @media #{$md-layout} {
        padding: 0px 8px;
     }
    @media #{$sm-layout} {
        padding: 0px 8px;
     }
 

 
}



.comparionTableContainer {
     width: 100%;
     padding: 0px 50px;
     margin-top: 100px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-content: flex-start;
     //overflow: scroll;

     @media #{$sm-layout} {
       padding: 0px 0px;
    }

}


.comparionTableHeaderRow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;

}

.comparisonCell{
    width: 25%;
    color: white;
    padding: 18px 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 0.1px solid rgba(255, 255, 255, 0.15);

    @media #{$sm-layout} {
        padding: 15px 4px;
        font-size: 8px;
     }
}

.comparionTableHeaderCell {
    background: #c894d4;
    font-weight: 600;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.32) 0px 3px 8px;

    

    @media #{$sm-layout} {
        font-weight: 600;
        font-size: 10px;
     }

}

.buttonContainer{
    width: 25%;
    //color: white;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-content: center;
   // border: 0.1px solid rgba(255, 255, 255, 0.15);

    @media #{$sm-layout} {
        padding: 10px 0px;
        font-size: 10px;
     }

}

.comparisonTabelcontactContainer{

    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.842);
    padding: 30px;
    border-radius: 30px;
    max-width: 650px;
    min-width: 640px;


    @media #{$sm-layout} {
        padding: 15px ;
        min-width:auto;
        max-width: auto;
        width: 97%;

     }


}

.comparisonTabelTopContainer{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.emailContainer{
    width: 100%;
    @media #{$sm-layout} {
        margin-top: 10px;

        width: 100%;

     }
   
}
.emailInput{
    border: 1px solid rgb(120 81 128 / 38%);
    background-color: white;
}

.buttonAndCaptcha{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px;
    margin: 20px 0px;

    @media #{$sm-layout} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        gap: 10px;
        margin: 10px 0px;



        width: 100%;

     }
}

.comparionTableContactButton{

    background-color: #000;
    color: white;
    border-radius: 50px;
    padding: 10px 45px;
    cursor: pointer;

     @media #{$sm-layout} {
        padding: 10px 45px;
        font-size: 18px;
     }
    
}




.comparionTableRow{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.comparionTableFeatureCell {

     background: #592c85;
     justify-content: flex-start;

}
.comparionTableNormalCell {
    background: #a570af;
    
}

.comparionTableFeatureResultCell {
    background: #3f1f5f;
    font-weight: 600;
    font-size: 22px;

    @media #{$sm-layout} {
        font-weight: 500;
        font-size: 10px;
     }

}
.comparionTableResultCell {
    background: #785180;
    font-weight: 600;
    font-size: 22px;

    @media #{$sm-layout} {
        font-weight: 500;
        font-size: 10px;
     }

}